var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "hero-content"
  }, [_c('span', {
    staticStyle: {
      "display": "block",
      "font-size": "14px",
      "color": "#3498db",
      "font-weight": "bold"
    }
  }, [_vm._v("Belajar Seumur Hidup")]), _c('h2', [_vm._v("Raih mimpi dan pengalaman belajar yang baru.")]), _c('p', [_vm._v("Platform kami memungkinkan Anda untuk belajar kapan saja dan di mana saja dengan materi yang lengkap, mengembangkan keterampilan relevan, meningkatkan peluang karir.")]), _c('br'), _c('br'), _c('div', {
    staticClass: "course-box"
  })]), _c('section', {
    staticClass: "brand-section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('h2', [_vm._v("Bekerja sama dengan")])]), _c('div', {
    staticClass: "brand-logos"
  }, [_c('img', {
    attrs: {
      "src": "https://cdn.rareblocks.xyz/collection/clarity/images/brands/1/logo-vertex.svg",
      "alt": "Vertex Logo"
    }
  }), _c('img', {
    attrs: {
      "src": "https://cdn.rareblocks.xyz/collection/clarity/images/brands/1/logo-squarestone.svg",
      "alt": "Squarestone Logo"
    }
  }), _c('img', {
    attrs: {
      "src": "https://cdn.rareblocks.xyz/collection/clarity/images/brands/1/logo-martino.svg",
      "alt": "Martino Logo"
    }
  }), _c('img', {
    attrs: {
      "src": "https://cdn.rareblocks.xyz/collection/clarity/images/brands/1/logo-waverio.svg",
      "alt": "Waverio Logo"
    }
  }), _c('img', {
    attrs: {
      "src": "https://cdn.rareblocks.xyz/collection/clarity/images/brands/1/logo-vertex.svg",
      "alt": "Vertex Logo"
    }
  }), _c('img', {
    attrs: {
      "src": "https://cdn.rareblocks.xyz/collection/clarity/images/brands/1/logo-squarestone.svg",
      "alt": "Squarestone Logo"
    }
  }), _c('img', {
    attrs: {
      "src": "https://cdn.rareblocks.xyz/collection/clarity/images/brands/1/logo-martino.svg",
      "alt": "Martino Logo"
    }
  }), _c('img', {
    attrs: {
      "src": "https://cdn.rareblocks.xyz/collection/clarity/images/brands/1/logo-waverio.svg",
      "alt": "Waverio Logo"
    }
  })])])]), _c('section', {
    staticClass: "categories"
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('h2', {
    staticClass: "section-title"
  }, [_vm._v("Categories")]), _c('div', {
    staticClass: "scroll-controls"
  }, [_c('button', {
    staticClass: "scroll-left"
  }, [_vm._v("←")]), _c('button', {
    staticClass: "scroll-right"
  }, [_vm._v("→")])])]), _c('div', {
    staticClass: "categories-list"
  }, [_c('div', {
    staticClass: "category-card"
  }, [_c('div', {
    staticClass: "image-container"
  }, [_c('img', {
    attrs: {
      "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOedfrL3DF-AxiEjh56WkVPve-d50hdpFhYg&s",
      "alt": "Web Development"
    }
  }), _c('span', [_vm._v("Manajemen")])])]), _c('div', {
    staticClass: "category-card"
  }, [_c('div', {
    staticClass: "image-container"
  }, [_c('img', {
    attrs: {
      "src": "https://media.istockphoto.com/id/1405961052/id/foto/analisis-bisnis-layar-big-data-dan-pertumbuhan-ekonomi-dengan-grafik-keuangan-konsep-pemasaran.jpg?s=612x612&w=0&k=20&c=WGjbslGuM_ATVJi2hy7RTJyDYrYRh94jOEoa3SowVbw=",
      "alt": "Graphic Design"
    }
  }), _c('span', [_vm._v("Keuangan")])])]), _c('div', {
    staticClass: "category-card"
  }, [_c('div', {
    staticClass: "image-container"
  }, [_c('img', {
    attrs: {
      "src": "https://media.istockphoto.com/id/1492180527/photo/digital-marketing-commerce-online-sale-concept-promotion-of-products-or-services-through.jpg?b=1&s=612x612&w=0&k=20&c=ZgJZYQJ5L5XWUsMjdDWZBQFgIZwArLpKkpwNKDMAMHQ=",
      "alt": "Marketing"
    }
  }), _c('span', [_vm._v("Marketing")])])]), _c('div', {
    staticClass: "category-card"
  }, [_c('div', {
    staticClass: "image-container"
  }, [_c('img', {
    attrs: {
      "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSa5iJkksMsdKRgZfd6-7vxmrmQqoh2SR6GhQ&s",
      "alt": "Data Science"
    }
  }), _c('span', [_vm._v("Bisnis")])])]), _c('div', {
    staticClass: "category-card"
  }, [_c('div', {
    staticClass: "image-container"
  }, [_c('img', {
    attrs: {
      "src": "https://png.pngtree.com/thumb_back/fh260/back_pic/04/48/14/415859da6c2caa9.jpg",
      "alt": "Business"
    }
  }), _c('span', [_vm._v("Teknologi Informasi")])])]), _c('div', {
    staticClass: "category-card"
  }, [_c('div', {
    staticClass: "image-container"
  }, [_c('img', {
    attrs: {
      "src": "https://png.pngtree.com/thumb_back/fh260/background/20240506/pngtree-a-business-team-and-its-leader-standing-together-leadership-concept-a-image_15670174.jpg",
      "alt": "Business"
    }
  }), _c('span', [_vm._v("Leadership")])])]), _c('div', {
    staticClass: "category-card"
  }, [_c('div', {
    staticClass: "image-container"
  }, [_c('img', {
    attrs: {
      "src": "https://images.pexels.com/photos/2253879/pexels-photo-2253879.jpeg",
      "alt": "Business"
    }
  }), _c('span', [_vm._v("Parenting")])])]), _c('div', {
    staticClass: "category-card"
  }, [_c('div', {
    staticClass: "image-container"
  }, [_c('img', {
    attrs: {
      "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRksckMA8j17F6YqPATNJHA0R_TyuPLNqBv5Q&s",
      "alt": "Business"
    }
  }), _c('span', [_vm._v("Motivasi")])])])])])]);
}]

export { render, staticRenderFns }