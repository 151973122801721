<template>
  <!-- <div class="section__container">
    <div class="header">
      <p>TESTIMONI SISWA</p>
      <h1>Apa yang dikatakan siswa kami tentang kami.</h1>
    </div>
    <div class="testimonials__scroll-container">
      <div class="testimonials__grid">
        <div class="card" v-for="(testimonial, index) in testimonies" :key="index">
          <span><i class="ri-double-quotes-l"></i></span>
          <p>{{ testimonial.comment }}</p>
          <hr />
          <img src="https://i.imgur.com/wvxPV9S.png">
          <p class="name">{{ testimonial.name }}</p>
        </div>
      </div>
    </div>
    <div class="footer">
      <h4>Bergabung bersama kami dan berbagi pengalaman!</h4>
      <p>
        Kami berkomitmen untuk memberikan layanan terbaik yang sesuai dengan kebutuhan Anda. Ayo, bantu kami dengan
        ulasan Anda untuk terus berkembang!
      </p>
      <button type="button" class="btn btn-warning">Warning</button>
    </div>

  </div> -->
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { BImg } from "bootstrap-vue";
import "swiper/css/swiper.css";
import { codeResponsive } from "./code";
import {
  BContainer,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BCardText,
  BCardGroup,
  BButton,
  BCarousel,
  BCarouselSlide,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BFormRating,
} from "bootstrap-vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
    BCardCode,
    BImg,
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BCardText,
    BCardGroup,
    BButton,
    BCarousel,
    BCarouselSlide,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BFormRating,
  },
  data() {
    return {
      codeResponsive,

      /* eslint-disable global-require */
      swiperData: [
        { img: require("@/assets/images/banner/banner-30.jpg") },
        { img: require("@/assets/images/banner/banner-31.jpg") },
        { img: require("@/assets/images/banner/banner-32.jpg") },
        { img: require("@/assets/images/banner/banner-33.jpg") },
        { img: require("@/assets/images/banner/banner-34.jpg") },
        { img: require("@/assets/images/banner/banner-35.jpg") },
      ],
      /* eslint-disable global-require */
      testimonies: [
        {
          image_ext: "jpg",
          name: "DODI PRASETYA",
          school_origin: "SMAN 2 BKL",
          achievement: "KEDOKTERAN - UNJA",
          comment:
            "Senang bisa gabung di ELP, makasih buat ELP, makasih buat tutornya yang okee.. jangan pernah bosan buat kita tambah pintar dan juara ya",
        },
        {
          image_ext: "jpg",
          name: "M. ARKOUN",
          school_origin: "SMAN 7 BKL",
          achievement: "KEDOKTERAN - UNBRAW",
          comment:
            "Les di ELP itu ASYIIKK.. pengajarnya ramah dan sabar saat menjelaskan matei. Jadi makin SEMANGAT kalau belajar di ELP",
        },
        {
          image_ext: "jpg",
          name: "YUYUN A.",
          school_origin: "SMAN 5 BKL",
          achievement: "DOKTER GIGI - UNBRAW",
          comment:
            "Walaupun banyak kesibukan, berkat ELP saya gak pernah ketinggalan materi di sekolah, Terimakasih ELP sudah menjadi partner terbaikku meraih nilai bagus di sekolah",
        },
      ],
      swiperOptions: {
        slidesPerView: 5,
        spaceBetween: 50,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
    };
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:wght@700&family=Poppins:wght@400;500;600&display=swap");

:root {
  --primary-color: #1a2c50;
  --secondary-color: #f4f7fe;
  --text-dark: #333333;
  --text-light: #767268;
  --extra-light: #ffffff;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  /* font-family: 'Noto Serif', serif; */
  font-family: "Poppins", sans-serif;
  color: var(--primary-color);
  background-color: var(--secondary-color);
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.section__container {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 1rem;
  text-align: center;
}

.header {
  margin-bottom: 2rem;
}

.header p {
  letter-spacing: 2px;
  font-size: 1rem;
  font-weight: 500;
}

.header h1 {
  font-family: "Noto Serif", serif;
  font-size: 2rem;
  font-weight: 900;
}

.testimonials__grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-bottom: 2rem;
}

.card {
  padding: 2rem;
  display: grid;
  gap: 1rem;
  background-color: var(--extra-light);
  border-radius: 5px;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.card i {
  font-size: 2.5rem;
  color: gold;
}

.card p {
  font-size: 1rem;
  font-weight: 500;
}

.card hr {
  width: 40px;
  margin: auto;
  color: var(--text-light);
}

.card img {
  width: 60px;
  height: 60px;
  margin: auto;
  border-radius: 100%;
  border: 1px solid var(--primary-color);
}

.card .name {
  font-size: 0.9rem;
  font-weight: 400;
  color: var(--text-light);
  transition: 0.3s;
}

.card .name:hover {
  color: var(--primary-color);
}

.footer h4 {
  font-family: "Noto Serif", serif;
  font-size: 1.25rem;
  font-weight: 900;
  margin-bottom: 1rem;
}

.footer p {
  max-width: 450px;
  margin: auto;
  font-size: 0.9rem;
  line-height: 1.5rem;
  margin-bottom: 2rem;
}

.footer button {
  padding: 1rem 2rem;
  outline: none;
  border: none;
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--extra-light);
  background-color: var(--primary-color);
  border-radius: 5px;
  cursor: pointer;
}

@media (width < 900px) {
  .testimonials__grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}

@media (width < 600px) {
  .testimonials__grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
