var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "brand-story"
  }, [_c('div', {
    staticClass: "icon"
  }, [_vm._v("📖")]), _c('h2', [_vm._v("Belajar Seumur Hidup")]), _c('p', [_vm._v("Kami adalah platform belajar online yang menghadirkan kesempatan bagi semua orang—akademisi maupun profesional—untuk mengembangkan keterampilan dan berbagi ilmu yang bermanfaat. Misi kami sederhana namun bermakna: setiap pengetahuan yang Anda pelajari di sini menjadi amal jariyah.")])]), _c('br'), _c('br'), _c('div', {
    staticClass: "container",
    staticStyle: {
      "margin-top": "50px"
    }
  }, [_c('h2', [_vm._v("Kenapa memilih "), _c('span', {
    staticClass: "highlight"
  }, [_vm._v("BSH")]), _vm._v(" ?")]), _c('p', {
    staticClass: "description"
  }, [_vm._v("sebagai aplikasi belajar yang menghadirkan metode pembelajaran online yang unik, praktis, fleksibel, dan menyenangkan")]), _c('div', {
    staticClass: "grid"
  }, [_c('div', {
    staticClass: "benefit-item"
  }, [_c('div', {
    staticClass: "icon"
  }, [_vm._v("📱")]), _c('div', {
    staticClass: "text"
  }, [_c('h3', [_vm._v("Mudah diakses")]), _c('p', [_vm._v("Belajar kapanpun dan di manapun di tempat yang kamu inginkan.")])])]), _c('div', {
    staticClass: "benefit-item"
  }, [_c('div', {
    staticClass: "icon"
  }, [_vm._v("🔒")]), _c('div', {
    staticClass: "text"
  }, [_c('h3', [_vm._v("Akses Kelas Penuh")]), _c('p', [_vm._v("Kamu mendapatkan akses penuh sehingga dapat ditonton berulang kali cukup dengan sekali bayar.")])])]), _c('div', {
    staticClass: "benefit-item"
  }, [_c('div', {
    staticClass: "icon"
  }, [_vm._v("🎨")]), _c('div', {
    staticClass: "text"
  }, [_c('h3', [_vm._v("Kelas bervariatif dan menarik")]), _c('p', [_vm._v("Berbagai macam kelas dapat kamu pelajari di Kelaskita.")])])]), _c('div', {
    staticClass: "benefit-item"
  }, [_c('div', {
    staticClass: "icon"
  }, [_vm._v("💸")]), _c('div', {
    staticClass: "text"
  }, [_c('h3', [_vm._v("Harga Kelas terjangkau")]), _c('p', [_vm._v("Nikmati berbagai macam kelas dengan harga terjangkau.")])])]), _c('div', {
    staticClass: "benefit-item"
  }, [_c('div', {
    staticClass: "icon"
  }, [_vm._v("👍")]), _c('div', {
    staticClass: "text"
  }, [_c('h3', [_vm._v("Mentor profesional")]), _c('p', [_vm._v("Belajar langsung dari yang ahli di bidangnya.")])])]), _c('div', {
    staticClass: "benefit-item"
  }, [_c('div', {
    staticClass: "icon"
  }, [_vm._v("📜")]), _c('div', {
    staticClass: "text"
  }, [_c('h3', [_vm._v("Mendapat e-Certificate")]), _c('p', [_vm._v("Kamu akan mendapat Sertifikat Kelas yang ditandatangani oleh mentor setelah lulus test akhir.")])])]), _c('div', {
    staticClass: "benefit-item"
  }, [_c('div', {
    staticClass: "icon"
  }, [_vm._v("💬")]), _c('div', {
    staticClass: "text"
  }, [_c('h3', [_vm._v("Berbagi Pengetahuan dan Pengalaman")]), _c('p', [_vm._v("Kamu dapat berbagi pengetahuan dan pengalaman dengan pengguna lain pada Diskusi Kelas.")])])]), _c('div', {
    staticClass: "benefit-item"
  }, [_c('div', {
    staticClass: "icon"
  }, [_vm._v("🎥")]), _c('div', {
    staticClass: "text"
  }, [_c('h3', [_vm._v("Video Berkualitas Tinggi")]), _c('p', [_vm._v("Video dengan kualitas tinggi memastikan bahwa kamu tidak melewatkan satu detail pun.")])])])])])]);
}]

export { render, staticRenderFns }