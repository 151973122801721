<template>
  <header class="header">
    <div class="hero-content">
      <span style="display: block; font-size: 14px; color: #3498db; font-weight: bold;">Belajar Seumur Hidup</span>
      <h2>Raih mimpi dan pengalaman belajar yang baru.</h2>
      <p>Platform kami memungkinkan Anda untuk belajar kapan saja dan di mana saja dengan materi yang lengkap,
        mengembangkan keterampilan relevan, meningkatkan peluang karir.</p>
      <br><br>
      <div class="course-box"></div>
    </div>
    <section class="brand-section">
      <div class="container">
        <div class="header">
          <h2>Bekerja sama dengan</h2>
        </div>
        <div class="brand-logos">
          <!-- Original logos -->
          <img src="https://cdn.rareblocks.xyz/collection/clarity/images/brands/1/logo-vertex.svg" alt="Vertex Logo" />
          <img src="https://cdn.rareblocks.xyz/collection/clarity/images/brands/1/logo-squarestone.svg"
            alt="Squarestone Logo" />
          <img src="https://cdn.rareblocks.xyz/collection/clarity/images/brands/1/logo-martino.svg"
            alt="Martino Logo" />
          <img src="https://cdn.rareblocks.xyz/collection/clarity/images/brands/1/logo-waverio.svg"
            alt="Waverio Logo" />

          <!-- Duplicate logos for seamless scroll -->
          <img src="https://cdn.rareblocks.xyz/collection/clarity/images/brands/1/logo-vertex.svg" alt="Vertex Logo" />
          <img src="https://cdn.rareblocks.xyz/collection/clarity/images/brands/1/logo-squarestone.svg"
            alt="Squarestone Logo" />
          <img src="https://cdn.rareblocks.xyz/collection/clarity/images/brands/1/logo-martino.svg"
            alt="Martino Logo" />
          <img src="https://cdn.rareblocks.xyz/collection/clarity/images/brands/1/logo-waverio.svg"
            alt="Waverio Logo" />
        </div>
      </div>
    </section>

    <section class="categories">
      <div style="display: flex; align-items: center;">
        <h2 class="section-title">Categories</h2>

        <!-- Tombol Panah -->
        <div class="scroll-controls">
          <button class="scroll-left">←</button>
          <button class="scroll-right">→</button>
        </div>
      </div>
      <div class="categories-list">
        <div class="category-card">
          <div class="image-container">
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOedfrL3DF-AxiEjh56WkVPve-d50hdpFhYg&s"
              alt="Web Development">
            <span>Manajemen</span>
          </div>
        </div>
        <div class="category-card">
          <div class="image-container">
            <img
              src="https://media.istockphoto.com/id/1405961052/id/foto/analisis-bisnis-layar-big-data-dan-pertumbuhan-ekonomi-dengan-grafik-keuangan-konsep-pemasaran.jpg?s=612x612&w=0&k=20&c=WGjbslGuM_ATVJi2hy7RTJyDYrYRh94jOEoa3SowVbw="
              alt="Graphic Design">
            <span>Keuangan</span>
          </div>
        </div>
        <div class="category-card">
          <div class="image-container">
            <img
              src="https://media.istockphoto.com/id/1492180527/photo/digital-marketing-commerce-online-sale-concept-promotion-of-products-or-services-through.jpg?b=1&s=612x612&w=0&k=20&c=ZgJZYQJ5L5XWUsMjdDWZBQFgIZwArLpKkpwNKDMAMHQ="
              alt="Marketing">
            <span>Marketing</span>
          </div>
        </div>
        <div class="category-card">
          <div class="image-container">
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSa5iJkksMsdKRgZfd6-7vxmrmQqoh2SR6GhQ&s"
              alt="Data Science">
            <span>Bisnis</span>
          </div>
        </div>
        <div class="category-card">
          <div class="image-container">
            <img src="https://png.pngtree.com/thumb_back/fh260/back_pic/04/48/14/415859da6c2caa9.jpg" alt="Business">
            <span>Teknologi Informasi</span>
          </div>
        </div>
        <div class="category-card">
          <div class="image-container">
            <img
              src="https://png.pngtree.com/thumb_back/fh260/background/20240506/pngtree-a-business-team-and-its-leader-standing-together-leadership-concept-a-image_15670174.jpg"
              alt="Business">
            <span>Leadership</span>
          </div>
        </div>
        <div class="category-card">
          <div class="image-container">
            <img src="https://images.pexels.com/photos/2253879/pexels-photo-2253879.jpeg" alt="Business">
            <span>Parenting</span>
          </div>
        </div>
        <div class="category-card">
          <div class="image-container">
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRksckMA8j17F6YqPATNJHA0R_TyuPLNqBv5Q&s"
              alt="Business">
            <span>Motivasi</span>
          </div>
        </div>
      </div>
    </section>


  </header>
</template>

<script>
import {
  BCard,
  BImg,
  BNavbar,
  BNavbarToggle,
  BCollapse,
  BTabs,
  BNavItem,
  BButton,
  BNav,
} from "bootstrap-vue";
import Ripple from 'vue-ripple-directive';
import {
  Swiper,
  SwiperSlide
} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import {
  codeCenterSlides
} from './code'
export default {
  components: {
    BCard,
    BNav,
    BTabs,
    BButton,
    BNavItem,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BImg,
    Swiper,
    SwiperSlide,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      img: require("@/assets/images/elp/ELP.png"),
      panah: require("@/assets/images/elp/panah melengkung.png"),
      swiperOptions: {
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },

      /* eslint-disable global-require */
      swiperData: [{
        img: 'https://www.mditack.co.id/wp-content/uploads/2020/09/Employee_Training_and_Development.jpg'
      },
      {
        img: 'https://www.marjon.ac.uk/media/2018-website-images-gdpr/courses/teacher-training/2018_Teacher_training_Banner.jpg'
      },
      {
        img: 'https://pix11.com/wp-content/uploads/sites/25/2020/07/teachersupply-2.jpg'
      },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      const scrollLeftButton = document.querySelector('.scroll-left');
      const scrollRightButton = document.querySelector('.scroll-right');
      const categoriesList = document.querySelector('.categories-list');

      if (scrollLeftButton && scrollRightButton && categoriesList) {
        scrollLeftButton.addEventListener('click', () => {
          categoriesList.scrollBy({ left: -250, behavior: 'smooth' });
        });

        scrollRightButton.addEventListener('click', () => {
          categoriesList.scrollBy({ left: 250, behavior: 'smooth' });
        });
      } else {
        console.error('Element not found');
      }
    });
  },


  props: {
    headerData: {
      type: Object,
      default: () => { },
    },


  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  color: #333;
  background: linear-gradient(180deg, #f9fafc, #e3f2fd);
}

.header {
  text-align: center;
  padding: 2rem 1rem;
}

.hero-content {
  max-width: 1200px;
  margin: 3rem auto;
}

.badge {
  display: inline-block;
  background-color: #e3f2fd;
  color: #1a73e8;
  padding: 0.5rem 1.5rem;
  border-radius: 15px;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.main-heading {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
}

.subheading {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 2rem;
}

.cta-btn {
  background-color: #FFE74C;
  color: white;
  padding: 0.8rem 2rem;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.cta-btn:hover {
  background-color: #155ab6;
}

.brand-section {
  padding: 3rem 1rem;
  background-color: white;
  margin-top: 4rem;
}

.brand-section .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.brand-section .header h2 {
  font-size: 1.25rem;
  font-weight: bold;
  color: #9ca3af;
  text-align: center;
  margin-bottom: 2rem;
}

.brand-logos {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
}

.brand-logos img {
  width: auto;
  height: 36px;
  margin: 0 2rem;
  opacity: 1;
  transition: transform 0.3s ease;
}

.brand-logos img:hover {
  transform: scale(1.1);
}

@keyframes logo-running {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

.brand-logos img {
  animation: logo-running 10s linear infinite;
}

.categories {
  max-width: 1200px;
  margin: 40px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 16px;
  position: relative;
}


.section-title {
  font-size: 1.3rem;
  color: #1a202c;
  font-weight: 600;
  display: inline-block;
  /* Untuk membuat judul sejajar */
  margin-right: 10px;
}

.scroll-controls {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  /* Posisikan di kanan judul */
  margin-left: auto;
}

.scroll-left,
.scroll-right {
  background-color: #ffffff;
  /* color: #1a202c; */
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.scroll-left:hover,
.scroll-right:hover {
  background-color: #e2e8f0;
}

.categories-list {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  padding-bottom: 10px;
  scroll-behavior: smooth;
  margin-top: 20px;
  /* Jarak antara judul dan daftar kategori */
}

.categories-list::-webkit-scrollbar {
  height: 8px;
}

.categories-list::-webkit-scrollbar-thumb {

}

.category-card {
  background-color: #ffffff;
  border-radius: 12px;
  text-align: center;
  width: 250px;
  box-sizing: border-box;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid #e2e8f0;
  cursor: pointer;
  flex-shrink: 0;
}

.category-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.image-container {
  position: relative;
  width: 100%;
  height: 180px;
  overflow: hidden;
  border-radius: 12px 12px 0 0;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  image-rendering: crisp-edges;
}

.course-box {
  background-image: url('https://i.imgur.com/Sr8DfJx.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #e6f1ff;
  width: 100%;
  height: 450px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image-container span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.1rem;
  color: #ffffff;
  font-weight: 500;
  text-transform: capitalize;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
</style>
