<template>
  <div>
    <div class="brand-story">
      <div class="icon">📖</div>
      <h2>Belajar Seumur Hidup</h2>
      <p>Kami adalah platform belajar online yang menghadirkan kesempatan bagi semua orang—akademisi maupun
        profesional—untuk mengembangkan keterampilan dan berbagi ilmu yang bermanfaat. Misi kami sederhana namun
        bermakna: setiap pengetahuan yang Anda pelajari di sini menjadi amal jariyah.</p>
    </div>

    <br><br>
    <!-- Why Choose Kelaskita Section -->
    <div class="container" style="margin-top: 50px;">
      <h2>Kenapa memilih <span class="highlight">BSH</span> ?</h2>
      <p class="description">sebagai aplikasi belajar yang menghadirkan metode pembelajaran online yang unik, praktis,
        fleksibel, dan menyenangkan</p>

      <div class="grid">
        <div class="benefit-item">
          <div class="icon">📱</div>
          <div class="text">
            <h3>Mudah diakses</h3>
            <p>Belajar kapanpun dan di manapun di tempat yang kamu inginkan.</p>
          </div>
        </div>

        <div class="benefit-item">
          <div class="icon">🔒</div>
          <div class="text">
            <h3>Akses Kelas Penuh</h3>
            <p>Kamu mendapatkan akses penuh sehingga dapat ditonton berulang kali cukup dengan sekali bayar.</p>
          </div>
        </div>

        <div class="benefit-item">
          <div class="icon">🎨</div>
          <div class="text">
            <h3>Kelas bervariatif dan menarik</h3>
            <p>Berbagai macam kelas dapat kamu pelajari di Kelaskita.</p>
          </div>
        </div>

        <div class="benefit-item">
          <div class="icon">💸</div>
          <div class="text">
            <h3>Harga Kelas terjangkau</h3>
            <p>Nikmati berbagai macam kelas dengan harga terjangkau.</p>
          </div>
        </div>

        <div class="benefit-item">
          <div class="icon">👍</div>
          <div class="text">
            <h3>Mentor profesional</h3>
            <p>Belajar langsung dari yang ahli di bidangnya.</p>
          </div>
        </div>

        <div class="benefit-item">
          <div class="icon">📜</div>
          <div class="text">
            <h3>Mendapat e-Certificate</h3>
            <p>Kamu akan mendapat Sertifikat Kelas yang ditandatangani oleh mentor setelah lulus test akhir.</p>
          </div>
        </div>

        <div class="benefit-item">
          <div class="icon">💬</div>
          <div class="text">
            <h3>Berbagi Pengetahuan dan Pengalaman</h3>
            <p>Kamu dapat berbagi pengetahuan dan pengalaman dengan pengguna lain pada Diskusi Kelas.</p>
          </div>
        </div>

        <div class="benefit-item">
          <div class="icon">🎥</div>
          <div class="text">
            <h3>Video Berkualitas Tinggi</h3>
            <p>Video dengan kualitas tinggi memastikan bahwa kamu tidak melewatkan satu detail pun.</p>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardTitle,
  BButton,
  BLink,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BOverlay,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BLink,
  },

  data() {
    return {
      loading: false,
      data: [],
    };
  },

  methods: {
    getColor(color) {
      let warna = "";
      if (color == "primary") {
        warna = "blue";
      } else if (color == "success") {
        warna = "green";
      } else if (color == "danger") {
        warna = "red";
      } else if (color == "info") {
        warna = "light-blue";
      } else if (color == "warning") {
        warna = "yellow";
      }
      return warna;
    },

    getData() {
      this.loading = true;
      let params = {
        orderCol: "price",
        order: "asc",
      };
      this.$store
        .dispatch("paketTryout/index", params)
        .then((res) => {
          this.loading = false;
          this.data = res.data.data.slice(0, 4);
        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },

    detail(item) {
      window.location = `paket-detail/${item.slug}`;
    },

    handleScrollAnimation(entries, observer) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fade-up');
          entry.target.classList.remove('fade-down');
        } else {
          entry.target.classList.add('fade-down');
          entry.target.classList.remove('fade-up');
        }
      });
    },

    setupIntersectionObserver() {
      const observer = new IntersectionObserver(this.handleScrollAnimation, {
        threshold: 0.1
      });

      document.querySelectorAll('.fade-in').forEach(element => {
        observer.observe(element);
      });
    }
  },

  mounted() {
    this.getData();
    this.setupIntersectionObserver();
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

* {
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
}

body {
  background-color: #F9F6F2;
}

.brand-story {
  background-color: #FAF8F4;
  padding: 40px;
  border-radius: 20px;
  width: 100%;
  /* margin: 60px auto; */
  text-align: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  color: #3A3A3A;
}

.brand-story .icon {
  font-size: 3rem;
  color: #F2C94C;
  margin-bottom: 20px;
}

.brand-story h2 {
  font-size: 2rem;
  color: #333;
  font-weight: 600;
  margin-bottom: 20px;
  letter-spacing: 1px;
}

.brand-story p {
  font-size: 1rem;
  color: #555;
  line-height: 2.0;
  width: 100%;
  margin: 0 auto;
}

/* Responsive Design */
@media (max-width: 768px) {
  .brand-story {
    padding: 30px;
  }

  .brand-story h2 {
    font-size: 1.8rem;
  }

  .brand-story p {
    font-size: 0.9rem;
  }
}

/* Grid Layout for Benefits */
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.benefit-item {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
}

.benefit-item h3 {
  font-size: 1.2rem;
  color: #333;
}

.benefit-item p {
  font-size: 1rem;
  color: #666;
}

.benefit-item .icon {
  font-size: 2rem;
  color: #FFE74C;
  margin-bottom: 10px;
}

/* Responsive Grid for Mobile */
@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr 1fr;
  }
}

.container h2 {
  font-size: 24px;
  font-weight: bold;
  color: #1a1a1a;
  margin-top: 10px;
  margin-bottom: 10px;
}

.container p {
  color: #666;
  font-size: 16px;
  margin-bottom: 20px;
}

</style>
